
import React, { useState, useEffect } from 'react';
import './../style/main.css';
import MiniSearch from 'minisearch';

import { BaseInput, DateInput, DropdownInput, TextInput } from './Inputs.js';
import { ResultElement, intersectMany, dateSearch, orSearch, rulesSearch } from './Results.js';

import JSZip from "jszip";

import Popup from 'reactjs-popup';

import axios from 'axios';

import { Outlet, Link } from "react-router-dom";

let months = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];

const all_fields = ["Trigram General Ref.", "General Ref. (UPC)", "Decision Ref. (ORD)", "Procedure trigram (ACT, APL, CC)", "Procedure Ref. ( Act)", "Incident Ref. (APP)", "Decision type", "Division", "Language", "Date of the decision", "Claimant", "Defendant", "Judges", "Rules (RoP)", "Articles (UPCA)", "Patent number", "PDF Files", "id"];


const fields = ["General Ref. (UPC)", "Division", "Language", "Date of the decision", "Claimant", "Defendant", "Rules (RoP)", "Patent number"];
const fields_labels = ["General Ref.", "Division", "Language", "Date", "Claimant", "Defendant", "Rules", "Patent n°"];
const field_strict_search = ["Patent number", "Division", "General Ref. (UPC)", "Decision Ref. (ORD)", "Procedure trigram (ACT, APL, CC)", "Procedure Ref. ( Act)", "Incident Ref. (APP)", "Trigram General Ref."];

const other_fields = ["Decision Ref. (ORD)", "Procedure trigram (ACT, APL, CC)", "Procedure Ref. ( Act)", "Incident Ref. (APP)", "Articles (UPCA)", "Judges", "Decision type", "Trigram General Ref."]
const other_fields_labels = ["Decision Ref.", "Procedure trigram", "Procedure Ref.", "Incident Ref.", "Articles", "Judges", "Decision type", "Trigram General Ref."];

const or_fields = ["Rules (RoP)", "Articles (UPCA)", "Language", "Patent number", "General Ref. (UPC)", "Decision Ref. (ORD)", "Procedure trigram (ACT, APL, CC)", "Procedure Ref. ( Act)", "Incident Ref. (APP)", "Division", "Claimant", "Defendant", "Judges", "Decision type", "Trigram General Ref."];

const languages = [{value: "EN", label: "English"}, {value: "FR", label: "French"}, {value: "DE", label: "German"}, {value: "IT", label: "Italian"}, {value: "NL", label: "Dutch"}];

// const divs = [{value: "Court of Appeal - Luxembourg", label: "Court of Appeal - Luxembourg"}, {value: "Cen. Div. Munich", label: "Cen. Div. Munich"}, {value: "Cen. Div. Milan", label: "Cen. Div. Milan"}, {value: "Cen. Div. Paris", label: "Cen. Div. Paris"}, {value: "Reg. Div. Nordic-Baltic", label: "Reg. Div. Nordic-Baltic"}, {value: "Loc. Div. Munich", label: "Loc. Div. Munich"}, {value: "Loc. Div. Milan", label: "Loc. Div. Milan"}, {value: "Loc. Div. Paris", label: "Loc. Div. Paris"}, {value: "Loc. Div. Brussels", label: "Loc. Div. Brussels"}, {value: "Loc. Div. Düsseldorf", label: "Loc. Div. Düsseldorf"}, {value: "Loc. Div. Mannheim", label: "Loc. Div. Mannheim"}, {value: "Loc. Div. Hamburg", label: "Loc. Div. Hamburg"}, {value: "Loc. Div. The Hague", label: "Loc. Div. The Hague"}, {value: "Loc. Div. Helsinki", label: "Loc. Div. Helsinki"}, {value: "Loc. Div. Copenhagen", label: "Loc. Div. Copenhagen"}, {value: "Loc. Div. Lisbon", label: "Loc. Div. Lisbon"}, {value: "Loc. Div. Ljubljana", label: "Loc. Div. Ljubljana"}, {value: "Loc. Div. Vienna", label: "Loc. Div. Vienna"}];
const divs = [{value: "Court of Appeal - Luxembourg", label: "Court of Appeal - Luxembourg"}, {value: "Cen. Div. Munich", label: "Cen. Div. Munich"}, {value: "Cen. Div. Milano", label: "Cen. Div. Milan"}, {value: "Cen. Div. Paris", label: "Cen. Div. Paris"}, {value: "Reg. Div. Nordic-Baltic", label: "Reg. Div. Nordic-Baltic"}, {value: "Loc. Div. Munich", label: "Loc. Div. Munich"}, {value: "Loc. Div. Milano", label: "Loc. Div. Milan"}, {value: "Loc. Div. Paris", label: "Loc. Div. Paris"}, {value: "Loc. Div. Brussels", label: "Loc. Div. Brussels"}, {value: "Loc. Div. Düsseldorf", label: "Loc. Div. Düsseldorf"}, {value: "Loc. Div. Mannheim", label: "Loc. Div. Mannheim"}, {value: "Loc. Div. Hamburg", label: "Loc. Div. Hamburg"}, {value: "Loc. Div. The Hague", label: "Loc. Div. The Hague"}, {value: "Loc. Div. Helsinki", label: "Loc. Div. Helsinki"}, {value: "Loc. Div. Copenhagen", label: "Loc. Div. Copenhagen"}, {value: "Loc. Div. Lisbon", label: "Loc. Div. Lisbon"}, {value: "Loc. Div. Ljubljana", label: "Loc. Div. Ljubljana"}, {value: "Loc. Div. Vienna", label: "Loc. Div. Vienna"}];


let isString = value => typeof value === 'string' || value instanceof String;

let miniSearch = new MiniSearch({
  fields: all_fields, // fields to index for full-text search
  storeFields: all_fields.concat(["PDF Files"]) // fields to return with search results
});




function AppMain() {

  const [cases, setCases] = useState([]);

  const [fieldsToggled, setFieldsToggled] = useState({
    "General Ref. (UPC)": false,
    "Decision Ref. (ORD)": false,
    "Procedure trigram (ACT, APL, CC)": false,
    "Procedure Ref. ( Act)": false,
    "Incident Ref. (APP)": false,
    "Division": false,
    "Language": false,
    "Date of the decision": false,
    "Claimant": false,
    "Defendant": false,
    "Rules (RoP)": false,
    "Patent number": false,
    "Judges": false,
    "Decision type": false,
    "Trigram General Ref.": false,
    "Articles (UPCA)": false,
  });

  const convertDate = (str) => {
    var parts = str.split("-");
    return new Date(parseInt(parts[2], 10),
                  parseInt(parts[1], 10) - 1,
                  parseInt(parts[0], 10))
  }

  useEffect(() => {

    axios.get(`${process.env.REACT_APP_BACKEND_ROOT_URL}/cases_public`).then(res => {
      let cases_ = res.data;
      cases_.forEach((item, i) => {
        item.id = i + 1;
      });

      cases_.sort((a,b) => {
        return convertDate(b["Date of the decision"]) - convertDate(a["Date of the decision"]);
      });

      miniSearch.removeAll();
      miniSearch.addAll(cases_);
      setCases(cases_);
    });}, []);

  const refsArray = all_fields.reduce((o, key) => ({ ...o, [key]: React.createRef()}), {})

  const [results, setResults] = useState([]);

  const [mainSearch, setMainSearch] = useState("");

  const lengthRef = React.createRef();


  const performFieldSearch = () => {
    let results = [];
    
    for (let field of Object.keys(fieldsToggled)) {
        if (fieldsToggled[field]) {

            let v = refsArray[field].current.state.value;

            console.log(v);
            
            if (v === null || (isString(v) && v.trim() === "")) continue;
            if (v.value) v = v.value;

            
            
            if (or_fields.includes(field) && v.length === 0) {
              v = [{value: refsArray[field].current.state.inputSave}];
              if (v.length === 1 && v[0].value === "") continue;
            }
            

            if (v.length === 0) continue;
            if (field === "Date of the decision") results.push(dateSearch(v, cases));
            else if (field === "Rules (RoP)") results.push(rulesSearch(v, miniSearch));
            else if (field === "Articles (UPCA)") results.push(rulesSearch(v, miniSearch, "Articles (UPCA)"));
            else if (or_fields.includes(field)) results.push(orSearch(v, miniSearch, field, field_strict_search.includes(field)));
            else if (field_strict_search.includes(field)) results.push(miniSearch.search(v, { fields: [field], prefix: true, fuzzy: 0}));
            else results.push(miniSearch.search(v, { fields: [field], prefix: true, fuzzy: 0.1}));
  
        }
    }
    if (results.length === 0) return [];
    return results.length === 1 ? results[0] : intersectMany(results);
  }

  const handleSearch = () => {
    let results = [];

    let fieldsSearchToggled = Object.values(fieldsToggled).filter(value => value === true) > 0;

    if (mainSearch === "[Latest Patents]" && fieldsSearchToggled === 0) {
      seeLatestDetails();
      return;
    } else if (mainSearch === "[Latest Patents]" && fieldsSearchToggled > 0) {
      setMainSearch("");
    }

    let res_main = mainSearch.trim() !== "" ? miniSearch.search(mainSearch, { prefix: true, fuzzy: 0.2 }) : [];
    let res_field = performFieldSearch();

    if (res_field.length === 0) {
      results = res_main;
    } else if (res_main.length === 0) {
      results = res_field;
    } else {
      results = intersectMany([res_main, res_field]);
    }
    
    let ref = lengthRef;
    ref.current.open();
  
    console.log(results);

    results.sort((a, b) => {
      return convertDate(b["Date of the decision"]) - convertDate(a["Date of the decision"]);
    });
    
    setResults(results);
  }


  const seeLatestDetails = () => {
    let results = cases.slice(0, 3);
    setMainSearch("[Latest Patents]");
    setResults(results);
  }

  const formatDate = (date) => {
    if (date === "") return "";
    let d = date.split("-");
    let day = d[0];
    let month = months[parseInt(d[1]) - 1];
    let year = d[2];
    return `${day} ${month} ${year}`;
  }

  const createFieldInput = (field) => {
    let ref = refsArray[field];
    if (field === "Date of the decision") return <DateInput key={"input" + field} ref={ref} />;
    else if (field === "Language") return <DropdownInput key={"input" + field} ref={ref} options={languages} field={field}/>;
    else if (field === "Division") return <DropdownInput key={"input" + field} ref={ref} options={divs} field={field}/>;
    else if (or_fields.includes(field)) return <BaseInput field={field} key={"input" + field} ref={ref}/>;
    else return <TextInput field={field} key={"input" + field} ref={ref}/>;
  }

  const downloadAll = () => {
    let urls = [];

    for (let result of results) {
      let pdfs = result["PDF Files"].split(",");
      for (let pdf of pdfs) {
        if (pdf) urls.push(pdf.split(":")[1].trim());
      }
    }

    const zip = new JSZip();

    const downloadFile = async (url) => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_ROOT_URL}/asset?file=${url}`);
      const blob = await response.blob();
      const fileName = url.substring(url.lastIndexOf("/") + 1);
      zip.file(fileName, blob);
    };

    const downloadFiles = async () => {
      for (let url of urls) {
        await downloadFile(url);
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        // saveAs(content, "files.zip");
        var csvURL = window.URL.createObjectURL(content);
        let a = document.createElement('a');
        a.href = csvURL;
        a.setAttribute('download', 'files.zip');
        a.click();
      });
    };

    downloadFiles();
  };


  return (
    <div className="App">
      <img src="images/Logo_August_Debouzy.png" alt="August Debouzy" className="august-logo"></img>
      <Link to="/ourteam" className='btn-style team-link'>About Us</Link>
      
      <h1 className='main-header'>Unified Patent Court<br/>Search Engine</h1>
      <div className="latest-cases-box">
        {cases ? cases.slice(0, 3).map((c) => {
          return <div key={c["Patent number"] + c["id"]} className="latest-case">
            <div>
              <h3>{formatDate(c["Date of the decision"])}</h3>
              <p>{c["Patent number"]}</p>
            </div>
            <p>{c["Division"]}</p>
          </div>})
        : null}
        <button className="see-more-latest btn-style" onClick={seeLatestDetails}>See details...</button>
      </div>
      <div className={`search`}>
        <input type="text" id="search-bar" value={mainSearch} onChange={(e) => setMainSearch(e.target.value)} 
          onKeyDown={(e) => {
              if (e.key === "Enter") {
                  handleSearch();
              }
          }}
        placeholder="Search all fields ..."></input>
            <button id="search-btn"  onClick={handleSearch}>
              <img src="images/search.svg" alt="Search" id="search-icon"></img>
            </button>
        <Popup className='results-length'
          ref={lengthRef}
          trigger={<div></div>}
          arrow={false}
        >
          Found {results.length} result{results.length > 1 ? "s" : ""}...
        </Popup>
        
      </div>

      <div id="field-container">
        {fields.map((field, i) => {
          return <button className={`field-button ${fieldsToggled[field] ? "field-selected" : ""}`} key={field}
            onClick={() => {
              setFieldsToggled((prevState) => ({
                ...prevState,
                [field]: !prevState[field]
              }));
            }}>{fields_labels[i]}</button>
        })}
        <Popup
          trigger={<button className='more-fields-btn'><img src="images/plus.svg" alt="+"></img></button>}
          position="left bottom"
          on="hover"
          closeOnDocumentClick
          mouseLeaveDelay={150}
          mouseEnterDelay={0}
          contentStyle={{display: "flex", flexDirection: "column", borderRadius: "1em", padding: "1em"}}
          arrow={false}
        >
          {other_fields.map((field, i) => {
            return <button className={`field-button ${fieldsToggled[field] ? "field-selected" : ""}`} key={field}
              onClick={() => {
                setFieldsToggled((prevState) => ({
                  ...prevState,
                  [field]: !prevState[field]
                }));
              }}>{other_fields_labels[i]}</button>;
          })}
        </Popup>
        
      </div>

      <div id="field-searches">
        
        {Object.entries(fieldsToggled).map(([key, value]) => {

            return value ? (
              createFieldInput(key)
            ) : null;
        })}

      </div>

        {results.length > 0 ?
          <div id="result-box">
            {results.map((result, i) => {
              return <ResultElement key={result["Patent number"] + "-" + i.toString()} case={result} />
            })}
          </div>: 
        null
        }

        {results.length > 0 && results.length < 9 ? 
        <button className="btn-style download-all" onClick={downloadAll}>Download All Documents</button> :
        null }
      

      
        
          <Link to="/cgu" className='cgu-link'>Terms of Use and Conditions</Link>
          <Popup
            trigger={<button className='info-btn'>
              <img src="images/circle-info.svg" alt="Info"></img>
              </button>}
              modal
            >
            <div className="info-box">
              <h3 style={{textDecoration: "underline"}}>How to use the search engine ?</h3>
              <p>
              The top of the page displays the three most recently entered decisions,
              clicking on the <b>See Details…</b> tab focuses the search on those decisions.
              <br/>
              For a search among all decisions available, you can search for an item
              across any field using the <b>Search All Fields…</b> bar. For a more advanced search,
              you can select one or more search filters of your choice by simply clicking on them.
              When a filter is selected, it appears in black. You then only need to provide the
              information in the various search fields. Note that the <b>+</b> tab allows you to
              select additional search filters.
              <br/><br/>
              The Boolean rules for the use of filters are set as follows:
              <ul style={{textAlign: 'left'}}>
                <li>each filter is combined with possible other selected filter(s) with an AND
              function</li>
                <li>within each filter, the various information entered are combined with an OR function </li>
                <li>the only exception to that latter principle is for the search filters <b>Articles</b> and <b>Rules</b> in which the various information entered are combined with an AND function</li>
              </ul>
              <br/>
              By clicking on the “search icon” on the right, search results are displayed together with relevant information
              and the opportunity to download both the original and automated English translated decisions.
              <br/>
              Thank you for using UPC Case Law, a site by August Debouzy!

              </p>
            </div>
          </Popup>
          <Outlet />
    </div>
  );
}


export default AppMain;
