import React from 'react';
import { Link, Outlet } from "react-router-dom";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

function CGU() {
    return (
        <div className='cgu-page'>
            <Link to="/" className='btn-style team-link'>Search Engine</Link>
            <Document file="CGU.pdf" onLoadError={console.error}>
                <Page pageNumber={1}/>
            </Document>
            <Outlet />
        </div>
    );
}

export default CGU;
