import { Outlet } from 'react-router-dom';
import React, { createRef, useState, useEffect } from 'react';
import * as XLSX from 'xlsx/xlsx.mjs';
import { ActiveTable } from 'active-table-react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FileUploader } from "react-drag-drop-files";
import axios from 'axios';

var cases;


const fileTypes = ["pdf", "PDF"];


var csvData;

const hash = (string) => {
    const utf8 = new TextEncoder().encode(string);
    return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');
        return hashHex;
    });
}


const fields = ["Trigram General Ref.", "General Ref. (UPC)", "Decision Ref. (ORD)", "Procedure trigram (ACT, APL, CC)", "Procedure Ref. ( Act)", "Incident Ref. (APP)", "Decision type", "Division", "Language", "Date of the decision", "Claimant", "Defendant", "Judges", "Rules (RoP)", "Articles (UPCA)", "Patent number", "PDF Files", "Cabinets/Avocats", "Traduction (oui | non)", "Date de l'assignation", "Nature", "Domaine", "Link"];


const convertToTable = (data) => {
    let result = [];
    result.push(fields);
    for (let c of data) {
        let row = [];
        for (let f of fields) {
            row.push(c[f] ? c[f] : "");
        }
        result.push(row);
    }
    return result;
}

const convertToJson = (data) => {
    let result = [];
    for (let i = 1; i < data.length; i++) {
        let row = {};
        for (let j = 0; j < fields.length; j++) {
            row[fields[j]] = data[i][j];
        }
        result.push(row);
    }
    return result;
}


const activeTableOptions = {
    customColumnsSettings: [
        { "headerName": "General Ref. (UPC)",  "isHeaderTextEditable": false },
        { "headerName": "Division", "isHeaderTextEditable": false },
        { "headerName": "Language", "defaultColumnTypeName": "Select", "isHeaderTextEditable": false },
        { "headerName": "Date of the decision", "defaultColumnTypeName": "Date d-m-y", "isHeaderTextEditable": false },
        { "headerName": "Claimant", "isHeaderTextEditable": false },
        { "headerName": "Defendant", "isHeaderTextEditable": false },
        { "headerName": "Rules (RoP)", "isHeaderTextEditable": false },
        { "headerName": "Articles (UPCA)", "isHeaderTextEditable": false },
        { "headerName": "Patent number", "isHeaderTextEditable": false },
        { "headerName": "PDF Files", "isHeaderTextEditable": false },
        { "headerName": "Cabinets/Avocats", "isHeaderTextEditable": false },
        { "headerName": "Traduction (oui | non)", "isHeaderTextEditable": false },
        { "headerName": "Date de l'assignation", "isHeaderTextEditable": false },
        { "headerName": "Nature", "isHeaderTextEditable": false },
        { "headerName": "Domaine", "isHeaderTextEditable": false },
        { "headerName": "Link", "isHeaderTextEditable": false },
        { "headerName": "Decision Ref. (ORD)", "isHeaderTextEditable": false },
        { "headerName": "Procedure trigram (ACT, APL, CC)", "isHeaderTextEditable": false },
        { "headerName": "Procedure Ref. ( Act)", "isHeaderTextEditable": false },
        { "headerName": "Incident Ref. (APP)", "isHeaderTextEditable": false },
        { "headerName": "Decision type", "isHeaderTextEditable": false },
        { "headerName": "Trigram General Ref.", "isHeaderTextEditable": false },
        { "headerName": "Judges", "isHeaderTextEditable": false },
    ],
    columnDropdown: {
        "isSortAvailable": true,
        "isDeleteAvailable": false,
        "isInsertLeftAvailable": false,
        "isInsertRightAvailable": false,
        "isMoveAvailable": false,
    },
    pagination: {
        "rowsPerPageSelect": {"prefixText": "Number of rows:", "options": [6, 9, 12, "All"]},
        "rowsPerPage": 5
    },
}


function AdminPage() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [files, setFiles] = useState([]);
    const [filter, setFilter] = useState("");
    const [dataChanged, setDataChanged] = useState(0);
    const inputRef = createRef();
    const uploadPopRef = createRef();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_ROOT_URL}/cases`).then(res => {
            cases = res.data;
            cases.forEach((item, i) => {
                item.id = i + 1;
            });
            csvData = convertToTable(cases);
        });
    }, []);

    const tryLogin = async () => {
        let h = (await hash(inputRef.current.value)).toUpperCase();
        if (h === process.env.REACT_APP_ADMIN_PASS) {
            setLoggedIn(true);
            inputRef.current.value = "";
        }
    }

    const listAllPDFs = () => { 
        axios.get(`${process.env.REACT_APP_BACKEND_ROOT_URL}/documents`).then(res => {
            setFiles(res.data);
        });
    }

    const saveChanges = () => {
        let data = convertToJson(csvData);
        console.log(data);
        axios.post(`${process.env.REACT_APP_BACKEND_ROOT_URL}/save`, data, {}).then(res => {
            console.log(res.statusText);
            setDataChanged(0);
        });
    }

    const exportXLSX = () => {
        var worksheet = XLSX.utils.json_to_sheet(convertToJson(csvData));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Cases");
        XLSX.writeFile(workbook, "cases.xlsx");
    }

    return (
        <div>
            {
                loggedIn ?
                <div className="admin-page">
                    <div className="first-admin-row">
                        <h1 className="admin-header">
                            <img src="images/small_logo.png" alt="Logo"></img>
                            Admin Portal
                        </h1>
                        <div className="end-buttons">
                            <Popup ref={uploadPopRef} trigger={<button className="upload-btn">Upload a PDF</button>} modal>
                                <UploadPopup parentRef={uploadPopRef}/>
                            </Popup>
                            <Popup onOpen={listAllPDFs} trigger={<button className="pdf-list-btn">List All PDFs</button>} modal>
                            <div className="list-popup">
                                <h1 className="list-title">List All PDFs</h1>
                                <input type="text" className="filter-input" placeholder="Filter" onChange={(e) => setFilter(e.target.value)} />
                                <div className='admin-file-list'>
                                    {files.map((file) => {
                                        if (filter && !file.includes(filter)) {
                                            return null;
                                        }
                                        return <div key={file} className="admin-file-name">
                                            <a href={`${process.env.REACT_APP_BACKEND_ROOT_URL}/asset?file=${file}`} target="_blank" rel="noopener noreferrer">{file}</a>
                                            <button className="delete-btn" onClick={() => {
                                                axios.post(`${process.env.REACT_APP_BACKEND_ROOT_URL}/delete`, {file: file}, {}).then(res => {
                                                    console.log(res.statusText);
                                                    listAllPDFs();
                                                });
                                            }}>x</button>
                                            </div>
                                    })}
                                </div>
                            </div>
                            </Popup>
                            <button className="save-changes-btn" disabled={dataChanged < 2} onClick={saveChanges}>Save Changes</button>
                            <button className="save-changes-btn" onClick={exportXLSX}>Export</button>
                            <button className="logout-btn" onClick={() => setLoggedIn(false)}>Logout</button>
                        </div>
                    </div>
                    <div className="second-admin-row">
                        <ActiveTable 
                            className="active-table"
                            onDataUpdate={(_) => {
                                setDataChanged(dataChanged + 1);
                            }}
                            data={csvData}
                            columnDropdown={activeTableOptions.columnDropdown}
                            customColumnsSettings={activeTableOptions.customColumnsSettings} 
                            pagination={activeTableOptions.pagination}
                            filter={true}
                            files={activeTableOptions.files}
                            displayAddNewColumn={false}
                        />
                    </div>
                    </div> :
                <div className="login-prompt">
                    <h1 className='login-header'>Admin Portal</h1>
                    <input type="password" className="login-input" onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            tryLogin();
                        }
                    }} placeholder="Password" ref={inputRef} />
                    <button className="login-btn" onClick={tryLogin}>Login</button>
                </div>
            }
        <Outlet />
        </div>
    );
}

function UploadPopup(props) {
    const [file, setFile] = useState([]);
    const uploadPopRef = props.parentRef;

    const handleChange = (file) => {
      setFile(file);
    };

    const uploadFiles = () => {
        const data = new FormData()
        for(var i = 0; i < file.length; i++) {
            data.append('file', file[i])
        }
        
        axios.post(`${process.env.REACT_APP_BACKEND_ROOT_URL}/upload`, data, {})
        .then(res => { 
            console.log(res.statusText);
            if (uploadPopRef.current) {
                uploadPopRef.current.close();
            }
        })
        
    }

    return (<div className="upload-popup">
            <h1 className="upload-title">Upload a PDF</h1>
            <FileUploader className="uploader" handleChange={handleChange} name="file" types={fileTypes} multiple={true}
            children={
                <div className="upload-zone">
                    <p>Drag and drop files here</p>
                    <p>or</p>
                    <p>Click to browse</p>
                    {file.length > 0 ?
                        <div>
                            <h2 className="small-upload-text">Uploading {file.length} file{file.length > 1 ? "s" : ""}...</h2>
                            <div className="file-names">{
                                [...Array(file.length).keys()].map((i) => {
                                    return <span className="file-name" key={file[i].name}>{file[i] ? file[i].name : ""}</span>
                                })
                            }</div>
                        </div> 
                    : null}
                </div>
            }
            />
            <button className="upload-file" onClick={uploadFiles}>Upload</button>
        </div>
    );
}

export default AdminPage;
