
import React from 'react';
import './../style/main.css';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";

import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';


const selectStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
        border: "none",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        fontSize: "1.3em"
    }),
}

// BaseInput component

class TextInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: []
        };
    }

    handleChange = (e) => {
        this.setState({ value: e.target.value });
    }

    render() {
        return (
            <div className="field-search">
                <label htmlFor="baseInput">{this.props.field}</label>
                <input type="text" className="baseInput" value={this.state.value} onChange={this.handleChange} placeholder={this.props.field}/>
            </div>
        );
    }
}

const createOption = (label) => ({
    label,
    value: label,
});

// RulesInput component
class BaseInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: '',
            inputSave: '',
            value: []
        };
    }

    handleKeyDown = (event) => {
        if (!this.state.inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                this.setState((prevState) => ({
                    value: [...prevState.value, createOption(prevState.inputValue)],
                    inputValue: ''
                }));
                event.preventDefault();
                break;
            default:
                return;
        }
    };

    handleInputChange = (newValue) => {
        this.setState((prevState) => ({value: prevState.value, inputValue: newValue }));
    }

    handleChange = (newValue) => {
        this.setState({ value: newValue });
    }

    render() {
        return (
            <div className="field-search">
                <label htmlFor="rulesInput">{this.props.field}</label>
                <div style={{width: "72%"}}>
                    <CreatableSelect
                        styles={selectStyles}
                        components={{ DropdownIndicator: null }}
                        inputValue={this.state.inputValue}
                        onBlurResetsInput={false}
                        isClearable
                        isMulti
                        onFocus={() => {
                            this.setState((prevState) => { return {inputValue: prevState.inputSave, inputSave: ""}});
                        }}
                        menuIsOpen={false}
                        onMenuClose={() => {this.setState({inputSave: this.state.inputValue})}}
                        onChange={this.handleChange}
                        onInputChange={this.handleInputChange}
                        onKeyDown={this.handleKeyDown}
                        placeholder={this.state.inputSave ? this.state.inputSave : "Type something and press ENTER..."}
                        value={this.state.value}
                    />
                </div>
            </div>
        );
    }
}

// DateInput component
class DateInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: []
        };
    }

    handleChange = (date) => {
        this.setState({ value: date });
    }

    render() {
        return (
            <div className="field-search">
                <label htmlFor="dateInput">Date of the decision</label>
                <Flatpickr
                    className="baseInput"
                    value={this.state.value}
                    placeholder='Date of the decision'
                    onChange={this.handleChange}
                    options={{ mode: "range", enableTime: false, dateFormat: "d/m/Y" }}
                />
            </div>
        );
    }
}

class DropdownInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ""
        };
    }

    handleChange = (val) => {
        this.setState({ value: val });
    }

    render() {
        return (
            <div className="field-search">
                <label htmlFor="dropdownInput">{this.props.field}</label>
                <div style={{width: "72%"}}>
                    <Select
                        styles={selectStyles}
                        isClearable
                        getOptionValue={(o) => o["value"]}
                        getOptionLabel={(o) => o["label"]}
                        onChange={this.handleChange}
                        name={this.props.field}
                        isMulti
                        options={this.props.options}
                    />
                </div>
            </div>
        );
    }
}


export { BaseInput, DateInput, DropdownInput, TextInput };
