import React from 'react';
import { Link, Outlet } from "react-router-dom";

function TeamPage() {
  return (
    <div className='about-page'>
      <Link to="/" className='btn-style team-link'>Search Engine</Link>
      <h1 className='about-header'>About Us</h1>
      <div className="peoples-row">
        <div className="people-card">
          <img class="profile-pic" src="images/desrousseaux.jpg" alt="Gregoire Desrousseaux" />
          <a className="name-header" href="https://www.august-debouzy.com/en/team/1577-gregoire-desrousseaux#Biography">
            GRÉGOIRE DESROUSSEAUX
            <img src="images/link.svg" alt="Link" />
          </a>
          <a className="email" href="mailto:gdesrousseaux@august-debouzy.com">gdesrousseaux@august-debouzy.com</a>
        </div>
        <div className="people-card">
        <img class="profile-pic" src="images/pochart.jpg" alt="François Pochart" />
          <a className="name-header" href="https://www.august-debouzy.com/en/team/1578-francois-pochart#Biography">
            FRANÇOIS POCHART
            <img src="images/link.svg" alt="Link" />
          </a>
          <a className="email" href="fpochart@august-debouzy.com">fpochart@august-debouzy.com</a>
        </div>
        <div className="people-card">
          <img class="profile-pic" src="images/martin.jpg" alt="Lionel Martin" />
          <a className="name-header"href="https://www.august-debouzy.com/en/team/1642-lionel-martin#Biography">
            LIONEL MARTIN
            <img src="images/link.svg" alt="Link" />
          </a>
          <a className="email" href="lmartin@august-debouzy.com">lmartin@august-debouzy.com</a>
        </div>
      </div>
      <h2 className="about-info-header">Patent Litigation before the French Courts and the UPC</h2>
      <p className="about-info-p">
        We assist our clients at all the pre-contentious and contentious stages of invalidity and infringement proceeding, before French courts and the UPC, up to the final decision as well as in settlement negotiations.  
        <br/>
        We strategically assess risks and benefits to explore all potential litigation options. Where relevant we explore the entire range of litigation options available (before civil, commercial and administrative courts, employment tribunals, the relevant PTOs, etc.) and work closely with the firm's other teams. 
        <br/>
        Our comprehensive experience allows us to respond swiftly to the most complex cases (interim injunction, declaration of non­infringement, claim to ownership of an invention, arbitration, mediation, FRAND litigation, coordination of pan-European litigation, etc.).  
        <br/>
        Our excellent knowledge of the UPC system and long-standing experience in managing cross-border cases, makes us very reactive for litigation before the UPC.  
      </p>
      <img class="profile-pic" src="images/upcteam.png" alt="François Pochart" style={{width: "70%"}}/>
      <Outlet />
    </div>
  );
}

export default TeamPage;
