
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppMain from "./AppMain/App";
import TeamPage from "./TeamPage/TeamPage";
import Admin from "./AdminPage/AdminPage";
import CGU from "./CGU"


import ReactGA from 'react-ga';
import { useEffect } from 'react'; // Import useEffect from 'react' package
const TRACKING_ID = "GTM-58KRWXCJ"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

export default function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<AppMain />} />
        <Route path="ourteam" element={<TeamPage />} />
        <Route path="admin" element={<Admin />} />
        <Route path="cgu" element={<CGU />} />
        <Route path="*" element={<AppMain />} />
      </Routes>
    </BrowserRouter>
  );
}
