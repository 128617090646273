import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Popup from 'reactjs-popup';


let months = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];

let contains = (list, x) => {
    for (let el of list) {
        if (x.id === el.id) return true;
    }
    return false;
}


let intersection = (arr1, arr2) => {
    const res = [];
    for(let i = 0; i < arr1.length; i++){
       if(!contains(arr2, arr1[i])){
          continue;
       };
       res.push(arr1[i]);
    };
    return res;
 };

let formatRules = (rules) => {
    // Split the string into an array of rules
    let ruleArray = rules.toString().split(", ");
    let uniqueRules = [...new Set(ruleArray)];
    let ruleNumbers = uniqueRules.map(rule => rule.replace("Règle ", ""));
    return ruleNumbers.join(", ");
}

let formatArticles = (articles) => {
    let artArray = articles.split(", ");
    let uniqueArts = [...new Set(artArray)];
    let artNumbers = uniqueArts.map(art => art.replace("Article ", ""));
    return artNumbers.join(", ");
}


export let intersectMany = (arrs) => {
    let res = arrs[0].slice();
    for(let i = 1; i < arrs.length; i++){
       res = intersection(res, arrs[i]);
    };
    return res;
 };

export let dateSearch = (dates, cases) => {
    let results = [];
    for (let c of cases) {
        let d = c["Date of the decision"].split("-");
        let date = new Date(parseInt(d[2]), parseInt(d[1]) - 1, parseInt(d[0]))

        if (date >= dates[0] && date <= dates[1]) results.push(c);
    }
    return results;
}

export let orSearch = (values, engine, field, strict) => {
    let results = [];
    let maxScore = 0; // Initialize maxScore to 0
    for (let v of values) {
        let r = engine.search(v.value, { fields: [field], prefix: strict ? false : true, fuzzy: strict ? 0.0 : 0.1});
        
        for (let el of r) {
            if (!contains(results, el)) {
                results.push(el);
                maxScore = Math.max(maxScore, el.score); // Update maxScore if necessary
            }
        }
    }
    return strict ? results.filter(x => x.score === maxScore) : results; // Return both results and maxScore
}

export let rulesSearch = (values, engine, field = "Rules (RoP)") => {
    console.log("feur");
    let results = [];
    let k = 0;
    for (let v of values) {
        results.push([])
        let r = engine.search(v.value, { fields: [field], prefix: true });

        for (let el of r) {

            let indexes = [];
            let s = el[field];

            let index = s.indexOf(v.value);
            while (index !== -1) {
                indexes.push(index);
                index = s.indexOf(v.value, index + 1);
            }
            
            let keep = false;
            for (let i of indexes) {
                
                if ((i + v.value.length !== s.length && [".", ",", " "].includes(s[i + v.value.length])) && (i !== 0 && [",", " "].includes(s[i - 1]))) keep = true;
                else if (i + v.value.length === s.length && [",", " "].includes(s[i - 1])) keep = true;
                else if (i === 0 && [".", ",", " "].includes(s[i + v.value.length])) keep = true;
            }

            if (!contains(results, el) && keep) {
                results[k].push(el);
            }
        }

        k++;
    }
    return intersectMany(results);
}


export class ResultElement extends React.Component {


    getPDFLangButtons = (value) => {
        let pdfs = value.split(",");
        let pdfButtons = [];
        for (let pdf of pdfs) {
            let lang = pdf.split(":")[0];
            let pdfName = pdf.split(":")[1];
            if (pdfName === undefined) {
                pdfName = lang;
                lang = "ORIGINAL"
            }
            pdfButtons.push(<a className="pdf-btn btn-style" key={pdf} href={`${process.env.REACT_APP_BACKEND_ROOT_URL}/asset?file=${pdfName.trim()}`} target="_blank" rel="noopener noreferrer">{lang.trim()}</a>);
        }
        return pdfButtons
    }

    formatDate = (date) => {
        if (date === "") return "";
        let d = date.split("-");
        let day = d[0];
        let month = months[parseInt(d[1]) - 1];
        let year = d[2];
        return `${day} ${month} ${year}`;
    }

    render() {
        return (
            <div className="result-element">
                <div className="first-res-row">
                    <div className="first-res-row-left">
                        <div className="date-info" data-tooltip-id="tooltip" data-tooltip-content="Date of the decision">
                            {this.formatDate(this.props.case["Date of the decision"])}
                        </div>
                        <div className="divloc-info" data-tooltip-id="tooltip" data-tooltip-content="Division and Language">
                            <b>{this.props.case["Division"]}</b> - {this.props.case["Language"]}
                        </div>
                    </div>
                    <div className="first-res-row-right">
                        {this.props.case["Decision type"]} - <b>{this.props.case["Patent number"]}</b>
                    </div>
                </div>
                <div className="refs-row">
                    <div className="ref-info" data-tooltip-id="tooltip" data-tooltip-content="General Ref. (UPC)">UPC_{this.props.case["Trigram General Ref."]}_{this.props.case["General Ref. (UPC)"]}</div>
                    <div className="ref-info" data-tooltip-id="tooltip" data-tooltip-content="Decision Ref. (ORD)">ORD_{this.props.case["Decision Ref. (ORD)"]}</div>
                    <div className="ref-info" data-tooltip-id="tooltip" data-tooltip-content="Procedure trigram and Ref. (Act)">{this.props.case["Procedure trigram (ACT, APL, CC)"]} {this.props.case["Procedure Ref. ( Act)"]}</div>
                    <div className="ref-info" data-tooltip-id="tooltip" data-tooltip-content="Incident Ref. (APP)">ACL_{this.props.case["Incident Ref. (APP)"]}</div>
                </div>
                <div className="main-row">
                    <div className="claimant-info">
                        <h2 className="main-label">Claimant</h2>
                        <p>{this.props.case["Claimant"] ? this.props.case["Claimant"] : "No Data .."}</p>
                    </div>
                    <div className="defendant-info">
                        <h2 className="main-label">Defendant</h2>
                        <p>{this.props.case["Defendant"] ? this.props.case["Defendant"] : "No Data .."}</p>
                    </div>
                </div>
                <div className="rules-row">
                    {this.props.case["Articles (UPCA)"] ? 
                        <div className="rules-info">
                        <h3 className="rule-label">Articles (UPCA)</h3>
                        <p>{formatArticles(this.props.case["Articles (UPCA)"])}</p>
                    </div> : null}
                    
                    {this.props.case["Rules (RoP)"] ? 
                    <div className="rules-info">
                        <h3 className="rule-label">Rules (RoP)</h3>
                        <p>{formatRules(this.props.case["Rules (RoP)"])}</p>
                    </div> : null}
                </div>
                <div className="judge-row">
                    <div className="judge-info">
                        <h2 className="main-label">Judge(s)</h2>
                        <p>{this.props.case["Judges"] ? this.props.case["Judges"] : "No Data .."}</p>
                    </div>
                </div>
                {this.props.case["PDF Files"] ? 
                    <Popup trigger={<button className='see-doc-btn'><img src="/images/doc.svg" alt="See Document"></img></button>} position="left center">
                        {this.getPDFLangButtons(this.props.case["PDF Files"])}
                    </Popup>
                : null}
                <Tooltip id="tooltip" delayShow={500} delayHide={0}/>
            </div>
        );
    }
}